import { Component, Input } from '@angular/core';
import { TenantService } from 'src/app/shared/services/tenant.service';

@Component({
  selector: 'app-empty-message',
  templateUrl: './empty-message.component.html',
  styleUrls: ['./empty-message.component.scss']
})
export class EmptyMessageComponent {
  @Input() message: string = 'There is no data available';
  @Input() routerLink?: string;
  @Input() linkText?: string;
  @Input() padding: string = '0';
  @Input() height: string = 'auto';
  @Input() backgroundLogo: boolean = false;

  constructor(private readonly tenantService: TenantService) { }

  get isologo() {
    return this.tenantService.styles.isotype_url ||
      this.tenantService.styles.logo_url;
  }
}
