import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { AlertController } from './shared/components/alert/services/alert-controller.service';
import { PopUpController } from './shared/components/pop-up/services/pop-up-controller.service';
import { SplashController } from './shared/components/splash/services/splash-controller.service';
import { ToastController } from './shared/components/toast/services/toast-controller.service';
import { TenantService } from './shared/services/tenant.service';
var tinycolor = require("tinycolor2");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked {
  hideNavbar: boolean = false;

  constructor(
    public alertController: AlertController,
    public popUpController: PopUpController,
    public toastController: ToastController,
    private splashController: SplashController,
    private changeDetectorRef: ChangeDetectorRef,
    private elem: ElementRef,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.splashController.initialSplash();
    this.setTenantCSSProperties();
  }

  ngAfterContentChecked(): void {
    const path = window.location.pathname.split('/')[1]
    this.hideNavbar = path === 'login' || path === 'reset' ||
      path === 'sign-up' || path === 'forgot' || path === 'landing-page';
    this.changeDetectorRef.detectChanges();
  }

  // Set business colors and styles
  setTenantCSSProperties() {
    this.elem.nativeElement.style.setProperty(
      '--app-primary-color',
      this.tenantService.styles.primary_color
    );
    this.elem.nativeElement.style.setProperty(
      '--app-secondary-color',
      this.tenantService.styles.secondary_color
    );
    this.setPrimaryLighterColor();
    this.setBackgroundGradient();
    this.setLightFlaresColor();
    //Set Favicon
    this.setFaviconAndTitle();
  }

  setPrimaryLighterColor() {
    const color = tinycolor(this.tenantService.styles.primary_color);
    // Clone color and make it lighter
    const pastelColor = color.clone().lighten(48);
    this.elem.nativeElement.style.setProperty(
      '--app-light-primary-color',
      pastelColor.toHexString()
    );

    // set lighter gradient
    const pastelGradient = pastelColor.clone().lighten(2).desaturate(30);
    const lighterGradient = color.isLight() ?
      `linear-gradient(to bottom, #FFFFFF, ${pastelGradient.toHexString()})` : '#FFFFFF';
    this.elem.nativeElement.style.setProperty(
      '--app-gradient-light-primary-color',
      lighterGradient
    );
  }

  setBackgroundGradient() {
    const startColor = tinycolor(this.tenantService.styles.primary_color);
    const secondColor = startColor.clone().lighten(30);
    const thirdColor = startColor.clone().lighten(50);

    const gradient =
      `linear-gradient(180deg, ${startColor} 0%, ${secondColor} 26%, ${thirdColor} 56%, #FFFFFF 100%)`;
    this.elem.nativeElement.style.setProperty(
      '--app-gradient-background-color',
      gradient
    );
  }

  setLightFlaresColor() {
    const color = tinycolor(this.tenantService.styles.primary_color);
    const colorWithOpacity = color.lighten(30).setAlpha(0.4);
    this.elem.nativeElement.style.setProperty(
      '--app-light-flares-color',
      colorWithOpacity.toHslString()
    );
  }

  setFaviconAndTitle() {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = this.tenantService.styles.isotype_url ||
      this.tenantService.styles.logo_url;
    const title = document.createElement('title');
    title.innerText = `CETV Now! ${this.tenantService.companyName}`;
    head.appendChild(link);
    head.appendChild(title);
  }
}
