import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmptyError, Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private baseUrl: string = environment.BASE_URL;
  private emailDomain: string = environment.EMAIL_DOMAIN;
  businessConfig: any = null;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { /**/ }

  /** Get the tenant information from the backend */
  getTenantAccountConfig(): Observable<any> {
    let url = window.location.href.split('/')[2];
    if (url.includes('localhost:')) {
      url = 'colsanitas.cetvdemo.com';
    }

    return this.http.get(`${this.baseUrl}accounts/config-by-domain?domain=${url}`);
  }

  async initTenantConfig(): Promise<any> {
    let config:any = false;
    try {
      config = await firstValueFrom(this.getTenantAccountConfig());
      config = config instanceof EmptyError ? false : config; 
    } catch(err) {
      config = false;
    }

    if(config) {
      this.businessConfig = config;
    } else {
      this.businessConfig = {
        company_name: "Demo doesn't exist",
        location_type: 'store',
        company_branding: {
          logo_url: 'https://www.cetvnow.com/wp-content/uploads/2023/05/CETV-Logo.png',
          primary_color: '#5ab8e3',
          secondary_color: '#CC6932'
        }
      }
      this.router.navigate(['/landing-page']);
      config = this.businessConfig;
    }
    return config;
  }

  get styles() {
    return this.businessConfig.company_branding;
  }

  get locationType() {
    return this.businessConfig.location_type;
  }

  get locationTypePlural() {
    return `${this.locationType}s`;
  }

  get companyName() {
    return this.businessConfig.company_name || '';
  }

  get demoUserEmail() {
    const userName = this.businessConfig.domain.split('.')[0];
    return `${userName}${this.emailDomain}`;
  }

  get screenLocations() {
    return this.businessConfig.screen_locations || [];
  }

  get demoExists() {
    return this.businessConfig?.id || null;
  }

  /**
   * Get a list of screen objects { label: string, value: string }
   * from a list of screen ids
   * @param screenLocationValues list od screen ids
   */
  getScreenLocationsObjectsList(screenLocationValues: string[]) {
    let screenLocations: any = [];
    for (let screenLocation of screenLocationValues) {
      const screen = this.screenLocations.find(
        (scrLoc: {label: string, value: string}) => scrLoc.value === screenLocation
      )
      if (screen) screenLocations.push(screen)
    }
  
    return screenLocations;
  };

  /** Get a list of the existing domains */
  getExistingDomains(): Observable<any> {
    return this.http.get(`${this.baseUrl}demo/domains`);
  }
}
