import { Component } from '@angular/core';
import { SplashController } from '../services/splash-controller.service';
import { TenantService } from 'src/app/shared/services/tenant.service';

@Component({
  selector: 'app-splash',
  styleUrls: ['./splash.component.scss'],
  template: `
    <div *ngIf="splashController.showSplash" [class]="splashController.splashAnim + ' overlay'">
      <img class="image" [src]="this.isologo"/>
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  `,
})
export class SplashComponent {
  constructor(
    public splashController: SplashController,
    private readonly tenantService: TenantService
  ) {}
  get isologo() {
    return this.tenantService.styles.isotype_url ||
      this.tenantService.styles.logo_url;
  }
}
